import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '../pages/LandingPage'

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: LandingPage
  },
  {
    path: '/works',
    name: 'Works',
    component: () => import('../pages/WorksPage.vue'),
    children: [
      {
        path: ':workId',
        name: 'Works',
        component: () => import('../pages/WorkModal'),
        props(route) {
          return {
            workId: +route.params.workId
          }
        }
      }
    ]
  },
  {
    path: '/movies',
    name: 'Movies',
    component: () => import('../pages/MoviesPage.vue'),
    children: [
      {
        path: ':movieId',
        name: 'Movies',
        component: () => import('../pages/MovieModal'),
        props(route) {
          return {
            movieId: +route.params.movieId
          }
        }
      }
    ]
  },
  {
    path: '/goods',
    name: 'Goods',
    component: () => import('../pages/GoodsPage.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../pages/ContactPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
