<template>
<div id="bg">
    <img :src="bgImage">
</div>
<nav-bar id="navbar"></nav-bar>
<router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
        <component :is="Component" />
    </transition>
</router-view>
</template>

<script>
import NavBar from '@/components/NavBar'
export default {
    name: 'App',
    data() {
        return {
            bgImage: require('@/assets/images/cat_god.svg')
        }
    },
    components: {
        NavBar
    },
    
}
</script>

<style lang="scss">
html {
    height: 100%;
    overflow: hidden;

    // text
    font: {
        family: 'Hiragino Sans';
        weight: 600;
        size: unquote("min(4.5vw, 9vh)");
    }
    color: $textColor; // default text color

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // prevent text selection
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    body {
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

#bg {
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;

    img {
        width: 80%;
        margin: auto;
    }
}

#navbar {
    position: static;
    margin-top: 10vw;
}

@media screen and (max-width: $tabletWidth) {
    .navbar-mobile-landing {
        margin-top: 0 !important;
        position: absolute !important;
        left: 8vw;
        // top configured dynamically in LandingPage.vue
    }
}

@media screen and (min-width: $tabletWidth) {
    html {
        font-size: unquote("min(2vw, 5vh)");
        background-size: 70%;
    }

    #navbar {
        position: static;
        margin-top: 2.5vw;
    }
}

// Transition
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.15s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

// General overrides
a {
    text-decoration: none;
    color: $textColor;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
</style>