<template>
<div id="landing-root">
    <div id="top">
        <div id="intro-card" class="card" ref="introCard">
            <img id="avatar" src="../assets/images/avatar.jpg">
            <div id="intro">
                <div id="title">初めまして、</div>
                <div id="caption">貴方の<div class="highlight">飼い猫</div> バーチャルライバーの<br id="break"><div class="highlight">花園セレナ</div>と申します！</div>
            </div>
        </div>
    </div>
    <div id="paragraph">
        国境を超えて「bilibili動画」「YouTube」等で活動しています！
        <br><br>
        日本と海外を繋ぐ架け橋になれるように日々<div class="highlight">頑張っています✨</div>
    </div>
    <div id="bottom-container">
        <div id="bottom-card" class="card">
            <a class="platform-row hover-highlight-contact"
                target="_blank" :href="link"
                v-for="[iconName, displayName, link] of platforms" :key="iconName">
                <img :src="require(`../assets/images/${iconName}.png`)">
                <p>{{ displayName }}</p>
            </a>
            <div id="separator"></div>
            <div id="fan-groups-container">
                <div>Fan Groups</div>
                <div>
                    <a href="https://t.me/HanazonoSerena" class="hover-highlight-contact" target="_blank" ><img src="@/assets/images/telegram.png"></a>
                    <a href="https://jq.qq.com/?_wv=1027&k=IrBVYvwY" class="hover-highlight-contact" target="_blank" ><img src="@/assets/images/qq.png"></a>
                </div>
            </div>
        </div>
        <img id="serena" src="../assets/images/serena.png">
    </div>
</div>
</template>

<script>
export default {
    name: 'landing-page',
    title: 'Hanazono Serena',
    data() {
        return {
            platforms: [
                ['yt', '花園セレナ', 'https://www.youtube.com/channel/UCRXBTd80F5IIWWY4HatJ5Ug'],
                ['twitter', '@hanazono_serena', 'https://twitter.com/hanazono_serena'],
                ['bili', '花园Serena', 'https://space.bilibili.com/380829248']],
        }
    },
    mounted() {
        this.navbar = document.getElementById('navbar')
        this.navbar.classList.add('navbar-mobile-landing')
        const updateNavbarFrame = () => {
            const navbar = this.navbar
            const introCard = this.$refs.introCard
            if (!navbar || !introCard) return

            const navbarHeight = navbar.getBoundingClientRect().height
            const bottom = introCard.getBoundingClientRect().bottom
            const space = parseInt(window.getComputedStyle(introCard).marginBottom, 10)
            const marginTop = (space - navbarHeight) / 2
            navbar.style.top = `${bottom + marginTop}px`
        }
        this.onresize = window.addEventListener('resize', updateNavbarFrame)
        // immediate trigger
        updateNavbarFrame()
    },
    unmounted() {
        this.navbar.classList.remove('navbar-mobile-landing')
        this.navbar = null
        window.removeEventListener('resize', this.onresize)
        this.onresize = null
    }
}
</script>

<style lang="scss" scoped>

#landing-root {
    z-index: -1;
}

#intro-card {
    width: 79vw;
    margin: 15vh auto 14vh;
    padding: 3%;
    position: relative;

    #avatar {
        width: unquote("min(25vw, 20vh)");
        height: unquote("min(25vw, 20vh)");
        border-radius: 100%;
        border: 5px solid $borderColor;
        position: absolute;
        top: -70%;
        left: 35%;
    }

    #title {
        font-size: 1.5em;
        margin-bottom: 0.5em;
    }
}

#paragraph {
    width: 85%;
    margin: 0 auto 6vh;
}

@media screen and (max-width: $tabletWidth) {

    #bottom-container {
        position: relative;
        
        #bottom-card {
            width: min-content;
            margin: 0 8%;
            font-size: 0.6em;

            .platform-row {
                display: inline-flex;
                justify-content: flex-start;
                padding: 5px;

                img {
                    height: 20px;
                    margin: auto 0;
                }

                * {
                    padding: 2px 5px;
                    margin: 5px 0;
                }
            }

            #separator {
                width: 80%;
                height: 4px;
                background-color: $borderColor;
                border-radius: 2px;
                margin: 5px auto 15px;
            }

            #fan-groups-container {
                div:first-child {
                text-align: center;  
                }

                div:last-child {
                    display: flex;
                    padding: 0 10%;
                    justify-content: space-around;
                    
                    a {
                        width: 15%;
                        margin: 10px;

                        img {
                            width: 100%;
                        }
                    }
                }
            } // fan-groups-container
        } // bottom-card

        #serena {
            position: absolute;
            z-index: -1;
            top: -5vh;
            left: 35vw;
            width: 80%;
        }
    } // #bottom-container
}

@media screen and (min-width: $tabletWidth) {

    .mobile-navbar {
        display: none !important;
    }

    #top {
        display: flex;
        flex-direction: column;
        margin-top: 5vh;

        #intro-card {
            order: 1;
            display: flex;
            width: 73%;
            margin: 8vh 4vw;
            padding: 10px;
            display: flex;
    align-items: center;

            #avatar {
                position: static;
                width: 8vw;
                height: 8vw;
                border-width: 8px;
            }

            #intro {
                margin-left: 20px;
            }
        }

        #navbar {
            order: 0;
        }
    } // top

    #break {
        display: none;
    }

    #paragraph {
        margin: 0 4.5vw;
        line-height: 1.5;

        br:last-child {
            display: none;
        }
    }

    #bottom-container {
        position: relative;
        height: 20vh;
        
        #bottom-card {
            position: fixed;
            z-index: 0;
            bottom: 4vw;
            left: 4vw;
            padding: 0 2vw;
            font-size: 0.6em;
            display: flex;

            .platform-row {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                padding: 5px;

                img {
                    height: 20px;
                }

                * {
                    padding: 2px 5px;
                    margin: 5px 0;
                }
            }

            #separator {
                width: 6px;
                height: auto;
                background-color: $borderColor;
                border-radius: 3px;
                margin: 3px 2vw;
            }

            #fan-groups-container {
                display: flex;
                align-items: center;

                div:first-child {
                text-align: center;  
                }

                div:last-child {
                    display: flex;
                    justify-content: space-around;
                    
                    a {
                        height: 30px;
                        width: 30px;
                        margin-left: 20px;

                        img {
                            height: 100%;
                            width: 100%;
                            margin: auto 10px;
                        }
                    }
                }
            }
        } // bottom-card

        #serena {
            position: fixed;
            z-index: 1;
            bottom: -5vh;
            right: -5vw;
            width: unquote("min(40vw, 60vh)");;
        }
    } // bottom-container
}

.highlight {
    color: $highlightColor;
    display: inline;
}

.hover-highlight-contact {
    transition: filter 0s, transform 0.3s;

    &:hover {
        filter: hue-rotate(345deg) saturate(1.8) brightness(0.7);
        transform: scale(1.03);
    }
}
</style>