<template>
    <div id="navbar">
        <router-link to="/">
            <div id="logo" :class="{'hover-highlight': true, card: true, _isLanding: isLanding}"> <!-- _isLanding passes this information from js to css -->
                <img :src="hanazono">
            </div>
        </router-link>
        <div id="_navbar" class="card">
            <div v-for="item in items" :key="item">
                <router-link :to="'/' + item.toLowerCase()">{{ item }} <div :class="{'selected-dot':true, shown: isSelected(item)}"></div> </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import {HANAZONO} from '@/assets/images/hanazono'

export default {
    name: "nav-bar",
    data() {
        return {
            items: ["Works", "Movies", "Goods", "Contact"],
            hanazono: HANAZONO,
        }
    },
    methods: {
        isSelected(item) {
            return this.$route.name !== undefined &&
                this.$route.name === item
        }
    },
    computed: {
        isLanding() {
            return this.$route.name === 'Landing'
        }
    }
}
</script>

<style lang="scss" scoped>
#navbar {
    display: flex;
    z-index: 100;
}

#_navbar {
    font: {
        family: 'Proxima Nova', 'Avenir Black';
        weight: 700;
    };
    width: 82vw;
    display: flex;
    justify-content: space-evenly;
    text-align: center;
    margin: 0 auto;

    >* {
        flex: 0 1 auto;
        margin: 3vw 0;
        position: relative;
    }

    a {
        transition: color 0.3s;
        &:hover {
            color: $highlightColor;
        }
    }
}

.selected-dot {
    visibility: hidden;
    opacity: 0;
    background-color: $highlightColor;
    width: 2vw;
    height: 2vw;
    border-radius: 1vw;
    position: absolute;
    top: -1vw;
    right: -2vw;
    transition: visibility 0s, opacity 0.3s linear;
}

.shown {
    visibility: visible;
    opacity: 1;
}

@media screen and (max-width: $tabletWidth) {
    #navbar {
        flex-direction: column;
        align-items: center;
    }

    #logo {
        img {
            width: 40vw;
            margin: 0 8vw;
        }
        margin-bottom: 4vh;
    }

    ._isLanding {
        display: none;
    }
}

@media screen and (min-width: $tabletWidth) {
    #logo {
        flex: 0 0 auto;
        display: block;
        margin-left: 4vw;

        img {
            width: 17vw;
            margin: 0 3vw;
            height: auto;
        }
    }

    #_navbar {
        flex: 0 0 auto;
        width: 60vw;
        height: fit-content;

        >* {
            margin: 17px auto;
        }
    }

    .selected-dot {
        width: unquote("min(0.8vw, 1.5vh)");
        height: unquote("min(0.8vw, 1.5vh)");
        left: -30%;
        top: 20%;
    }
}

.router-link-active {
    color: $highlightColor;
}

</style>